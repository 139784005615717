/* Shimmer.css */
.shimmer-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.shimmer-row {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #efefef;
  padding-bottom: 16px;
}

.shimmer-logo {
  width: 80px;
  height: 70px;
  border-radius: 6px;
  flex-shrink: 0;
  background: #efefef;
}

.shimmer-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.shimmer-line {
  width: 100%;
  height: 16px;
  background: #efefef;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.shimmer-title {
  width: 50%;
}

.shimmer-subtitle {
  width: 30%;
}

.shimmer-text {
  width: 80%;
}

.shimmer-line::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
