@keyframes shimmer {
    to {
       background-position-x: 0%;
    }
 };
 .dbsh-conatiner{
    width: 100%;
    height: 100vh;
    padding: 1rem;
    padding-top: 0;
    .shsd-header{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        background: none;
        .shsd-heading{
            width: 250px;
            height: 100%;
        }
        .shsd-search{
            width: 400px;
            height: 100%;
            border-radius: 20px;
        }
    }
    .dbsh-filter-header{
        display: flex;
        gap: 10px;
        background: none;
        width: 100%;
        height: 40px;
        margin-top: 20px;
        .dbsh-filter-item{
            width: 20%;
            height: 100%;
            border-radius: 20px;
        }
    }
    .dbsh-cards-conatiner{
        width: 100%;
        display: flex;
        background: none;
        justify-content: space-between;
        padding: 1rem;
        align-items: center;
        .dbsh-card-header{
            display: flex;
            gap: 15px;
            background: none;
            .dbsh-card-img{
                width: 60px;
                height: 60px;
                border-radius: 15px;
            }
            .dbsh-card-details{
                background: none;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                .dbsh-card-name{
                    width: 180px;
                    height: 12.5px;
                }
                .dbsh-card-loaction{
                    width: 130px;
                    height: 12.5px;
                }
                .dbsh-card-detals{
                    width: 150px;
                    height: 12.5px;
                }
            }
        }
        .dbsh-card-btn{
            width: 160px;
            height: 40px;
            border-radius: 20px;
        }
    }
    .dbsh-border-line{
        width: 100%;
        height: 1px;
    }
    div{
        background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
        animation: shimmer 1s infinite linear;
        background-size: 300%;
        background-position-x: 100%;
    }
 }