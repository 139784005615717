#tabstrip::-webkit-scrollbar,
#tabstrip-first::-webkit-scrollbar {
  display: none;
}

.tabstrip-children {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
