#Community-Requests {
  padding: 2rem 3rem;
  width: 100%;
  // height: auto;
  // overflow-y: auto;
  border-radius: 8px;
  display: flex;
  // border: 1px solid #f8f8f8;
  // background-color: #f8f8f8;
  gap: 30px;

  .right-card {
    width: 75%;
    height: auto;
    overflow-y: auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    border: 1px solid #efefef;
    background-color: #fff;
    border-radius: 12px;
    padding: 24px;
    min-height: 70vh;
  }
  .headings {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
  }
  .left-card {
    width: 25%;
    border: 1px solid #efefef;
    box-shadow: 0px 1.5px 4px -1px #0a090b12;
    border-radius: 12px;
    gap: 8px;
    background-color: #ffffff;
    height: 200px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .item-text {
    font-size: 14px;
    font-weight: 400px;
  }
  .admin-name {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: center;
  }

  .joiningDate {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: center;
  }
  .position {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: center;
  }

  @media only screen and (max-width: 1000px) {
    .left-card {
      display: none;
    }
    .right-card {
      width: 100%;
    }
  }
}

#Community-Requests-Page{
  padding: 12px 0;
  display: flex;
  gap: 5rem;
}

@media only screen and (max-width: 775px) {
  #Community-Requests {
    padding: 1rem !important;

    .community-image-container {
      width: 45px;
      height: 45px;
    }
  }
}
