$white: #ffffff;
$light: #efefef;
$grey: #6f767e;
$lightGrey: #ced5de;
$dark: #1a1d1f;
$green: #83bf6e;
$danger: #be0f00;
$yellow: #ffa700;
$primary: #3d9be9;
$inter: Inter, sans-serif;
$plus_jakara_sans: Plus Jakarta Sans, sans-serif;
// 3d9eb9

.text-primary {
  color: $primary !important;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-30 {
  width: 30%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}
