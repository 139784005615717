@import "../../../../../assets/scss/variables.scss";

.event-preview {
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 1.2rem;
  display: flex;
  align-items: stretch;
  gap: 1rem;

  .event-time {
    font-size: 14px;
    font-weight: 500;
    color: $primary;
  }

  .event-poster {
    width: 100px;
    height: 80px;
    border-radius: 6px;
  }
}

#EventPreview {
  .event-details {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      width: 32px;
      height: 32px;
    }

    .calender-icon {
      padding: 16px;
      border-left: 1px solid #ced5de;
      padding-left: 30px;
    }

    .date-preview {
      width: 54px;
      height: 54px;
      border-radius: 6px;
      padding: 8px;
      background-color: #f4f4f4;
      display: flex;
      align-items: center;
      flex-direction: column;
      flex-shrink: 0;

      .date {
        font-size: 22px;
        font-weight: 600;
        line-height: 1;
      }

      .month{
        color: #3D9BE9;
        font-weight: 500;
      }
    }
  }
}
