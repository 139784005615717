@import "../../../assets/scss/style.scss";

$base-font-size: 16px;

.navbar_container {
  position: sticky;
  z-index: 200;
  width: 100%;
  border-bottom : 1px solid #CED5DE;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar {
  background: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 60px; /* 10px 60px converted to rem */
  height: 4.5rem; /* 72px converted to rem */
  max-width: 110rem;
  width: 100%;
}

.navbar img {
  height: 1.688rem; /* 27px converted to rem */
  cursor: pointer;
}

.left_side {
  display: flex;
  align-items: center;
  gap: 1.5rem; /* 24px converted to rem */
  justify-content: flex-end;

  i {
    color: grey;
    // font-size: 1.25rem; /* 20px converted to rem */
    height: 1.375rem; /* 22px converted to rem */
    width: 1.25rem; /* 20px converted to rem */
  }
}

.right {
  display: flex;
  gap: 10px;
  align-items: center;
}

.hamburger {
  display: none;
}
@media screen and (max-width: 49.125rem) {
  .mid {
    display: none;
  }
}

@media screen and (max-width: 1233px) {
  /* hamburger */
  .hamburger {
    display: block;
    cursor: pointer;
  }
  .bar {
    background-color: $dark;
    width: 20px;
    height: 2px;
    display: block;
    margin: 4px;
    -webkit-transition: 0.3s;
    transition: 0.3s ease-in-out;
  }
  /* adding active class to the bars  */
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(5px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
  }
}

@media screen and (max-width: 767px) {
  .navbar {
    padding: 1rem;
  }
}
