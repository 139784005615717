#Community-Page {
  margin: 0 40px;
  padding: 12px 0;
  display: flex;
  gap: 3rem;
}
#Community-Page {
  .common {
    background-color: #ffffff;
    border: 1px solid #CED5DE;
    border-radius: 12px;
 
    box-shadow: 0px 1.5px 4px -1px rgba(10, 9, 11, 0.0705882353);
    padding: 16px;
  }
  .truncate-text {
    display: inline-block; /* or inline-flex */
    max-width: 220px; /* Adjust this value based on your desired max width */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .community-left,
  .community-right {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .community-card {
    background-color: #ffffff;
    border: 1px solid #CED5DE;
    box-shadow: 0px 1.5px 4px -1px #0a090b12;
    border-radius: 12px;
    padding: 1%;
    width: 100%;
    height: max-content;
  }

  .community-main-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .user-profile {
  }

  .similar_group {
    display: flex;
    gap: 14px;
    font-size: 14px;
    color: #4e5660;
    font-weight: 400;
    flex-direction: column;
  }

  .all_members {
  }

  .top-info {
    padding: 0 32px !important;
  }
}

#Community-Page > :first-child{
  width: 70%;
  max-width: 70%;
}

#Community-Page > :last-child {
  width: 30%;
  max-width: 30%;
}

.card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 0px !important;
  padding-bottom: 12px;
  padding-top: 12px;
  border-top: 1px solid #CED5DE !important;
  border-radius: 0;
}

.posts-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.not-found-icon {
  svg,
  path {
    width: 50px;
    height: 50px;
  }
}

#LinkPreview,
#EventPreview {
  // border: 1px solid #ced5de;
  border-radius: 8px;
  margin-top: 16px;
  background-color: #fff;

  .preview-img {
    width: 100%;
    max-height: 280px;
    border-radius: 8px 8px 0 0;
    object-fit: cover;
    border: 1px solid #ced5de;
  }

  .h5-i {
    color: #1d1d1d;
  }

  .content {
    padding: 16px;
    border: 1px solid #ced5de;
    border-top: 0;
    border-radius: 0 0 8px 8px;
    // border-top: 1px solid #ced5de;
  }
}

#EventPreview {
  .content {
    padding: 16px 16px;
  }
  .post-video{
    border-radius: 8px 8px 0 0 !important;
    margin-top: 0 !important;
  }
}
