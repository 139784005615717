@keyframes shimmer {
    to {
       background-position-x: 0%;
    }
 };
.shimmerProduct-container{
    width: 100%;
    height: 100vh;
    padding: 1rem;
    padding-top: 2rem;
    .shimmerProduct-heading{
        width: 250px;
        height: 60px;
    }
    .ShimmerProduct-image{
        width: 100%;
        height: 400px;
        margin-top: 20px;
        border-radius: 25px;
    }
    .shimmerProduct-stats-container{
        width: 100%;
        height: 250px;
        margin-top: 20px;
        background: none;
        display: flex;
        justify-content: space-around;
        .shimmer-stat-item{
            width: 30%;
            background: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
            .stat-number{
                width: 150px;
                height: 60px;
            }
            .stat-name{
                width: 250px;
                height: 40px;
            }
        }
    }
    div{
        background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
        animation: shimmer 1s infinite linear;
        background-size: 300%;
        background-position-x: 100%;
    }
}

@media (min-width: 200px) and (max-width:900px) {
    .shimmerProduct-container{
        width: 100vw;
        overflow: hidden;
    }
    .shimmerProduct-stats-container{
        width: 100vw !important;
        justify-content:space-between !important;
        gap: 200px;
        overflow: scroll;
        margin-top: 0 !important;
        :first-child{
            padding-left: 100px;
        }
    }
}