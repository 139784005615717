.share-div {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: 14px;
  justify-content: space-between;
  margin-bottom: 1rem;
  overflow-x: auto;
  white-space: nowrap;
  span {
    font-weight: 500;
    font-size: 14px;
  }

  .share-link-container {
    flex: 1; // Allow the link container to shrink as needed
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block; // Ensure it’s a block element
    padding-right: 10px; // Add padding for spacing from the Copy button
  }
}

.comment-section {
  margin-top: 1rem;

  .existing-comments {
    padding-top: 1rem;
    border-top: 1px solid #eaeaea;
    .comment {
      display: flex;
      gap: 10px;
    }
    .comment-detail {
      background-color: #fff;
      border: 1px solid #efefef;
      padding: 10px 12px;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      align-items: start;
      min-width: 177px;
      .comment-author {
        font-weight: 600;
        font-size: 14px;
        margin-right: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  .add-comment {
    display: flex;
    gap: 10px;
    .comment-input-container {
      display: flex;
      align-items: center;
      border-radius: 6px;
      padding: 6px;
      background-color: #fff;
      width: 100%;
      border: 1px solid #efefef;

      .comment-input {
        flex: 1;
        border: none;

        background-color: #fff;
        outline: none;
        font-size: 14px;
        height: 32px;
        padding: 10px;
      }

      input::placeholder {
        font-weight: 400;
        background-color: transparent;
      }

      .post-button {
        margin-left: 10px;
        color: #fff;
        border-radius: 24px;
        padding: 3px 16px !important;
        height: 32px !important;
        border: none;
        cursor: pointer;
        &:disabled {
          background-color: #b0b3b8;
          cursor: not-allowed;
        }
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
}

.share-options {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;

  a {
    text-decoration: none !important;
    display: flex;
    gap: 10px;
    color: #1a1d1f !important;
  }

  svg {
    fill: #1a1d1f;

    path : {
      fill: #1a1d1f;
    }
  }
}

.copy-icon-container {
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
}
