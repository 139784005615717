.singlecard-container {
  width: 100%;
  border-bottom: 1px solid var(--30, #efefef);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;
}

.singlecard-container.filter-tab {
  border-top: 1px solid var(--30, #efefef);
}
.singlecard-container.active-tab {
  padding-top: 0px;
}
.left-content {
  display: flex;
  align-items: center;
}
.dots {
  display: inline-block;
}
.post-date {
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #6f767e;
}

.member-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
}

.member-name {

  font-size: 14px;
  margin-bottom: 6px;
  display: flex;
  font-weight: bold;
}
.item-text {
  font-size: 14px;
  font-weight: 400px;
}

.member-community-details {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 14px;
  color: #666;
  margin: 0;
}

.community-detail {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.01em;
  text-align: left;
  line-height: 0;
}

.action-btn-container {
  display: flex;
  gap: 10px;
}
.action-btn-secondary {
  height: 38px;
  gap: 10px;
  border-radius: 60px;
  border: 1px 0px 0px 0px;
  padding: 8px 16px;  
  border: 1px solid #9a9fa5;
  font-size: 14px;
  font-weight: 500;
  background: #fff;
}
.action-btn-primary {
  color: #3d9be9;
  height: 38px;
  gap: 10px;
  padding: 8px 16px;
  border-radius: 60px;
  border: 1px 0px 0px 0px;
  border: 1px solid #3d9be9;
  font-size: 14px;
  background: #fff;
  font-weight: 500;
}
.action-btn-primary {
  color: #3d9be9;
  height: 38px;
  gap: 10px;
  padding: 8px 16px;
  border-radius: 60px;
  border: 1px 0px 0px 0px;
  border: 1px solid #3d9be9;
  font-size: 14px;
  background: #fff;
  font-weight: 500;
}
.action-btn-disabled {
  border: 1px solid var(--50, #c0c4c9);
  height: 38px;
  gap: 10px;
  padding: 8px 16px;
  border-radius: 60px;
  border: 1px 0px 0px 0px;
  font-size: 14px;
  font-weight: 500;
}

.memeber-container {
  display: flex;
  gap: 5px;
}
