.common {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 1.5px 4px -1px #0a090b12;
  padding: 16px;
}

.post-view{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.04) !important;
}
#PostView {
  a {
    font-weight: 500;
    color: #3d9be9;
  }

  .post-image {
    border-radius: 8px;
    width: 100%;
    margin-top: 16px;
    // max-height: 300px;
  }


  .post-video {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 8px;
    display: block;
    background-color: #303030;
    margin-top: 16px;
  }

  .social-footer {
    border-top: 1px solid #CED5DE;
    margin-top: 5px;
    display: flex;
    justify-content: space-around;
    padding-top: 14px;

    .center-div {
      cursor: pointer;
    }
  }

  .text-view {
    background-color: #ffffff !important;
    // border: 1px solid #ced5de;
    box-shadow: none;
    white-space: pre-wrap;
  }
}

.likes-comments {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  .comments:hover {
    text-decoration: underline;
    color: #3d9be9;
  }

  .likes {
    svg {
      width: 19px !important;
      height: 19px !important;
    }
  }
}

.location-info {
  background-color: #ffffff;
  padding: 16px;
  border: 1px solid #ced5de;
  border-radius: 0 0 10px 10px;
  border-top: 0;
}

/* Styling for the post content */
.locked-postView {
  position: relative;
  width: 100%;
  // max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 12px;
  // height: 300px;
  // border: 1px solid #ccc;
  // border-radius: 10px;
  // background-color: #fff;
}

.locked-postView {
  min-height: 400px;
  /* Dummy post content */
  .post-content {
    color: #333;
    filter: blur(5px); /* Apply blur to content */
  }

  /* Overlay styles */
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent overlay */
    backdrop-filter: blur(5px); /* Additional blur */
    border-radius: 12px;
  }

  /* Overlay content */
  .overlay-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333;

    p {
      font-size: 18px;
      font-weight: 500;
      width: 70%;
    }
  }

  /* Lock icon styling */
  .lock-icon {
    font-size: 40px;
    margin-bottom: 10px;
    // border: 1px solid #333;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  /* Join Now button styling */
  .join-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .join-button:hover {
    background-color: #0056b3;
  }
}

.common {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 1.5px 4px -1px #0a090b12;
  padding: 16px;
}

#PostView {
  a {
    font-weight: 500;
    color: #3d9be9;
  }

  .post-image {
    border-radius: 8px;
    width: 100%;
    margin-top: 16px;
  }

  .social-footer {
    border-top: 1px solid #CED5DE;
    margin-top: 5px;
    display: flex;
    justify-content: space-around;
    padding-top: 14px;

    .center-div {
      cursor: pointer;
    }
  }

  .text-view {
    // border: 1px solid #ced5de;
    box-shadow: none;
    white-space: pre-wrap;
  }
}

.post-shimmer {
  /* Shimmer styles */
  .shimmer-wrapper {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 16px;
    position: relative;
    overflow: hidden;
    background-color: #fff; /* Set background to white */
  }

  .shimmer-title {
    height: 24px; /* Increased height for better visibility */
    width: 70%; /* Adjusted width */
    margin-bottom: 12px; /* Increased spacing */
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    border-radius: 4px;
  }

  .shimmer-content,
  .shimmer-image {
    height: 16px; /* Increased height for content */
    width: 100%;
    margin-bottom: 10px; /* Increased spacing */
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    border-radius: 4px;
  }

  .shimmer-image {
    height: 120px; /* Increased image height */
    border-radius: 4px; /* Added border-radius for image */
    margin-bottom: 12px; /* Spacing below image */
  }

  .shimmer-navbar {
    display: flex;
    align-items: center;
    margin-bottom: 12px; /* Increased margin for navbar */
  }

  .shimmer-avatar {
    height: 56px; /* Increased avatar size */
    width: 56px; /* Increased avatar size */
    border-radius: 50%;
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    margin-right: 12px; /* Increased margin for better spacing */
  }

  .shimmer-name,
  .shimmer-date {
    height: 16px; /* Increased height */
    width: 50%;
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    border-radius: 4px;
  }

  .shimmer-date {
    width: 40%; /* Adjusted width for date */
    margin-top: 6px; /* Reduced margin on top */
  }

  @keyframes shimmer {
    to {
      background-position-x: 0%;
    }
  }
}

.edit-comment-input {
  padding: 0;
  background-color: transparent;
  border: none;
}

.edit-comment-input:focus {
  background-color: transparent;
  border: none;
}
