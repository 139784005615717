@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&family=Plus+Jakarta+Sans:wght@200;300;400;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@1,14..32,100..900&display=swap");
@import "scrollbar";
@import "variables";
@import "custom";
@import "inputs";
@import "topography";
@import "page_footer";
@import "spacings";
@import "accordian";
@import "pass_strength";
@import "custom_checkbox";
@import "customRadio";
@import "filters";

:root {
  font-family: "Inter", sans-serif;
  line-height: 1.5;
  font-synthesis: none;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: auto;
}

button {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
}

#root {
  font-family: "Inter", sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

// Toast React Hot Toast Customization

button{
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.go2534082608:after,
.go2534082608:before {
  background: rgb(190, 15, 0) !important;
}

.go2344853693:after {
  border-color: #83bf6e !important;
}

.go2344853693,
.go2534082608 {
  background-color: #fff !important;
}

.dot,
.dot-dark,
.dot-blue {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 7px;
  background-color: #6f767e;
  display: inline-block;
}

.dot-dark {
  background-color: #1e273b;
}

.dot-blue {
  background-color: #3d9be9;
}

.cursor-pointer {
  cursor: pointer;
}

p {
  margin-bottom: 1rem;
}

.actions-icon-custom {
  font-size: 18px;
  color: #4e5660;
  width: 23px;
  height: 18px;
}

@media (min-width: 200px) and (max-width: 900px) {
  .dot-dark {
    margin-left: 10px;
  }
}

hr {
  color: #efefef;
  opacity: 1;
}

p,
div {
  word-wrap: break-word;
}

.filters-not-allowed{
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.65;
  position: relative;
  filter: blur(1px);
}

.filter-info-icon{
  pointer-events: auto; /* Allows interaction */
  cursor: pointer;
  position: absolute;
  right: 10px; /* Adjust spacing */
  color: #007bff; /* Info icon color */
  font-size: 18px;
  top: 40%;
}

.aum-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.spacing-issue-rte {
  p {
    strong {
      padding-left: 5px !important;
    }
  }
}

.add-remove-container {
  margin-top: 2.6rem;
  cursor: pointer;
}

.remove-feild {
  font-weight: 700;
  color: #be0f00;
  font-size: 14px;
  cursor: pointer;
}

.add-feild {
  font-weight: 700;
  color: #3d9be9;
  font-size: 14px;
  cursor: pointer;
}

.indicator-dot,
.small-indicator-dot {
  background-color: #3d9be9;
  color: white; /* Set the text color */
  font-size: 8px; /* Adjust the font size as needed */
  border-radius: 50%; /* Make it a circle */
  width: max-content;
  width: 7px;
  height: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  font-weight: 500;
  margin-right: -3px;
}

.small-indicator-dot {
  margin-top: 4px;
}

.product-link {
  color: $primary;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 13vw;
}

.uniform-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 9vw;
}

.line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.gm-style-iw-d {
  max-width: 200px;
}
.required-field::after {
  content: "*";
  color: $danger;
  padding-left: 5px;
}

.center-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.grecaptcha-badge {
  visibility: hidden;
}

.w-20 {
  width: 20%;
}

.text-truncate {
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide the overflowing content */
  text-overflow: ellipsis; /* Show '...' at the end of the truncated text */
  max-width: 200px; /* Set a maximum width for truncation */
}

.normal-heading {
  color: $dark;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 16px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-container:hover::before {
  content: attr(data-title);
  position: absolute;
  bottom: 115%; /* Position the tooltip above the element */
  left: 500%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 3px 12px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 12px;
  z-index: 100;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.tooltip-container:hover::after {
  content: "";
  position: absolute;
  bottom: 100%; /* Position the arrow above the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent transparent; /* Arrow pointing down */
  z-index: 99;
  opacity: 1;
}

.tooltip-container::after,
.tooltip-container::before {
  opacity: 0;
  pointer-events: none;
}

.tooltip-container:hover::after,
.tooltip-container:hover::before {
  opacity: 1;
}

.filters-not-allowed {
  button {
    cursor: none !important;
  }
}

.footer-container{
  background-color: $dark;
  display: flex;
  justify-content: center;
  
}