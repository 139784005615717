/* ImageViewer.css */

.image-container,
.community-image-container {
  width: 80px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 6px;
  margin-right: 12px;
}

.community-image-container {
  height: 70px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  border: 1px solid #efefef;
}

.no-image {
  object-fit: contain !important;
  border: 1px solid #efefef;
}
