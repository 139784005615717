#Community-Container {
  hr{
    border-top: 1px solid var(--40, #CED5DE)
  }
  .data-container {
    border: 1px solid #efefef;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 20px;
  }

  .data-row {
    display: flex;

    .row-content-container {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #efefef;
      gap: 40px;
      width: 100%;
      .row-content {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
}

.row-content-container:first-child {
  width: 80%;
}
.row-content-container:last-child {
  width: 20%;
}
