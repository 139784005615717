.custom-menu-list {
  i {
    display: flex;
    width: 18px;
    margin-right: 4px;

    svg {
      width: 18px !important;
      height: 18px !important;
    }
  }
}
