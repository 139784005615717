#LinkPreview{
    .shimmer-img {
        width: 100%;
        height: 150px;
        background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
        border-radius: 8px 8px 0 0 ;
        animation: shimmer-animation 1.5s infinite linear;
      }
      
      .shimmer-content {
        flex: 1;
      }
      
      .shimmer-title {
        width: 80%;
        height: 20px;
        background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
        margin-bottom: 10px;
        border-radius: 4px;
        animation: shimmer-animation 1.5s infinite linear;
      }
      
      .shimmer-text {
        width: 100%;
        height: 14px;
         background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
        border-radius: 4px;
        animation: shimmer-animation 1.5s infinite linear;
      }
      
      @keyframes shimmer-animation {
        0% {
          background-position: -400px 0;
        }
        100% {
          background-position: 400px 0;
        }
      }
}