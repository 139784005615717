.filter-container {
  position: relative;
  /* Set to relative to control the positioning of its children */
  cursor: pointer;
}

.filter-header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;

  .header {
    color: $dark;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;

    i {
      font-size: 14px;
    }
  }
}

.filter-box,
.right-filter-box {
  top: calc(100% + 12px);
  display: flex;
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid var(--30, #efefef);
  flex-direction: column;
  z-index: 90;
}

.filter-box {
  left: 0;
}

.right-filter-box {
  right: 0;
}

.filter-data-box {
  display: flex;
  flex-direction: row;
  // min-height: 300px;
  // max-height: 300px;
  /* Set your desired maximum height */
  overflow-y: visible;
  /* Add this to enable scrolling when the content exceeds max-height */
}

.filter-key-box {
  display: flex;
  flex-direction: column;
  min-width: 170px;
  max-width: 170px;
  border-right: 1px solid #efefef;
  max-height: 300px;
  overflow-y: auto;
}

.filter-key {
  display: flex;
  padding: 12px 18px;
  // justify-content: center;
  align-items: center;
  color: $dark;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  padding-right: 3px;
  justify-content: space-between;
}

.selected {
  &::before {
    position: absolute;
    margin-bottom: 4px;
    background-color: #3d9be9;
    color: white; /* Set the text color */
    font-size: 8px; /* Adjust the font size as needed */
    border-radius: 50%; /* Make it a circle */
    width: max-content;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    font-weight: 500;
    margin-right: -3px;
  }
}

.filter-key:hover,
.filter-key:focus,
.filter-key:active,
.active-key {
  background-color: $light;
}

.disabled-key {
  opacity: 0.7;
  pointer-events: none !important;
  cursor: none;
}

.filters-column {
  padding: 15px;
  padding-top: 0;
  // padding-right: 70px;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  min-width: 170px;
  width: 250px;
  height: 100%;
  max-height: 40vh;
  overflow: scroll;
  .filters-options {
    max-height: 250px;
    overflow-y: auto;
  }
}

label {
  font-size: 14px !important;
}

@media (min-width: 200px) and (max-width: 480px) {
  .filter-mobile-container {
    .filter-box {
      scale: 0.7;
      top: calc(100% - 41px);
      right: -57px;
    }
  }
  .filter-box {
    scale: 0.8 !important;
    transform-origin: top right;
    // left: 0;
    width: max-content;
  }
}

.filterbtn {
  height: 37px !important;
  font-size: 13px;
  padding: 0 14px !important;

  i {
    font-size: 10px;
  }
}

.active-filter {
  border: 1px solid #1a1d1f !important;
  background-color: #f4f4f4 !important;
}

.multi-dot-indicator {
  position: relative;
  min-width: calc(max-width + 10px);
  padding-right: 30px !important;
  &::before {
    content: "";
    position: absolute;
    right: 11.5px;
    margin-bottom: 4px;
    background-color: #3d9be9;
    color: white; /* Set the text color */
    font-size: 8px; /* Adjust the font size as needed */
    border-radius: 50%; /* Make it a circle */
    width: max-content;
    width: 7px;
    height: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    font-weight: 500;
    margin-right: -3px;
  }
}
