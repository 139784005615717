/* ImageViewer.css */

.image-container,
.community-image-container {
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  border-radius: 6px;
  margin-right: 12px;
  position: relative;
}

.community-image-container {
  height: 70px;
}

.community-image {
  width: 100%;
  height: 100%;
  // object-fit: cover;
  border-radius: 50%;
}

.no-image {
  object-fit: contain !important;
  border: 1px solid #efefef;
}

.icon-img {
  width: 28px;
  height: 28px;
  position: absolute;
  bottom: 0px;
  right: -4px;
  .path {
    fill: blue;
  }
}
