.filters-item-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;

  .active {
    background-color: white;
    border: 1px solid #3d9be9;
    color: #3d9be9;
    transition: color 0.3s ease, border 0.3s ease; /* Add transition properties */
  }
}

.filters-item {
  height: 35px;
  padding: 5px 16px;
  border-radius: 30px;
  border: 1px solid #c0c4c9;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.7;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.3s ease; /* Add transition property for background */
}
