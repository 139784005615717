.dashboard_container {
    display: flex;
    max-width: 110rem;
    width: 100%;
    margin: 0 auto;
}

.dashboard_content_container {
    flex: 1;
    min-height: 130vh;
    width: calc(100vw - 288px);
}

@media (min-width: 200px) and (max-width:900px) {
    .dashboard_container {
        width: 100%;

        .dashboard_content_container {
            width: 100%;
            min-height: 0vh;
        }
    }

}