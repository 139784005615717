@import "../../../../assets/scss/variables.scss";

.notification {
  .grey-i {
    color: grey;
    line-height: normal;
    height: 1.375rem;
    width: 1.25rem;
    display: flex;
  }
  position: relative;
  display: inline-block;
}

.notificationCount,
.communityNotificationCount {
  position: absolute;
  top: -3px; /* Adjust the top position as needed */
  right: -1px; /* Adjust the right position as needed */
  background-color: #ff6a55; /* Set the background color as needed */
  color: white; /* Set the text color */
  font-size: 8px; /* Adjust the font size as needed */
  border-radius: 50%; /* Make it a circle */
  width: max-content;
  min-width: 12px;
  min-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.communityNotificationCount {
  top: -1px;
  right: -4px;
}

.dropdown {
  position: absolute;
  top: 100%;
  right: -20px;
  width: 300px;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 10px;
  box-shadow: 0px 4px 16px 0px #0000000f;
  padding: 0.5rem 0;
  z-index: 1; /* Ensure the dropdown is above other elements */
}

.dropdownItem {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #1a1d1f !important;

  &:hover {
    background-color: #f4f4f4;
  }
}

.unreadNotification {
  background-color: aliceblue;

  &:hover {
    background-color: aliceblue;
  }
}

.shimmer {
  width: 100%;
  height: 50px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  border-bottom: 1px solid #efefef;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  to {
    background-position: -200% 0;
  }
}

.notification_p {
  font-size: 14px;
  margin-bottom: 5px;
}
