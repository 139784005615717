.close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid #c0c4c9;
  padding: 11px;
  border-radius: 50%;

  svg {
    height: 24px;
    width: 25px;
    line-height: normal;
  }
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 32px;
  border-color: #efefef;

  i {
    font-size: 14px;
  }
}

.modal {
  display: none;
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 1200;
}

div:is(.modal-normal) {
  ::-webkit-scrollbar {
    display: unset !important;
    width: unset !important;
  }
}

div:is(.modal-normal-content)::-webkit-scrollbar {
  display: block !important;
  width: 10px !important;
}

.modal-content,
.modal-normal-content {
  width: 550px;
  border-radius: 30px;
  /* Default border-radius for all sides */
  overflow: auto;
  /* Enable scrollbar when content overflows */
  max-height: 90vh;
  /* Set a maximum height to trigger the scrollbar */
  background-color: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
}

.modal-normal-content,
.modal-normal-body {
  // overflow: visible;
}

.modal-normal-body {
  padding: 0.5rem 1.5rem;
  // overflow: scroll;
}

/* Adjust the border-radius when there is a scrollbar */
.modal-content.scrollable {
  border-radius: 30px 30px 0 0;
}

.modal-body {
  padding-left: 32px;
  padding-top: 0;
  padding-right: 32px;
  max-height: 90vh;
  overflow-y: auto;
}

.business-modal-content {
  background-color: white;
  padding: 20px;
  width: 800px;
  border-radius: 16px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
  height: auto;
  max-height: 97vh;
  /* Adjust the height as needed */
  overflow-y: auto;
}

.modal-heading {
  color: var(--100, #1a1d1f);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.modal.active {
  display: flex;
}

.delete-modal-subtitle {
  color: #545c6e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 30px;
}

.cancel-btn {
  border-radius: 8px;
  border: 1px solid #d9dde7;
  background-color: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel-btn > span {
  color: #1e273b;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.confirm-btn-delete-modal {
  width: 352px !important;
  height: 50px !important;
  border-radius: 8px !important;
  margin-bottom: 20px;
}

.modal-description {
  color: #545c6e;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

@media (min-width: 200px) and (max-width: 600px) {
  .modal-normal-content {
    width: 100vw !important;
    scale: 0.9;
    overflow-x: hidden !important;
  }
}
@media (min-width: 600px) and (max-width: 900px) {
  .modal-normal-content {
    scale: 0.8;
    overflow-x: hidden !important;
  }
}

@media (max-width: 1300px) {
  .scale-the-modal {
    scale: 0.7;
  }
}
@media (max-width: 1450px) {
  .scale-the-modal {
    scale: 0.8;
  }
}
